import { Box } from "@mui/material";
import React from "react";

const FilterComponent = ({ children }) => {
  return (
    <Box
      sx={{
        padding: "1%",
        paddingX: "4%",
        backgroundColor: "rgb(226, 226, 226)",
        margin: "2%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      {children}
    </Box>
  );
};

export default FilterComponent;
