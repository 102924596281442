// Importing necessary modules from Redux Toolkit and universal-cookie
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

// Initialize cookies to manage persistent user data in the browser
const cookies = new Cookies();

// Define the initial state of the authentication slice
const addressBookSliceInitialState = {
  // Retrieve userAll from cookies if available, otherwise initialize as an empty object
  masterInfoFilter: {
    addressNb: "",
    fullName: "",
    addressType: "",
  },
  masterInfoData: [],
  contextMenuData: [],
  contextMenuChanges: [],
  companyList: [],
  companyIDSelected: "",
  phoneNbTypes: [],
};

// Create a slice for authentication using Redux Toolkit's createSlice function
const addressBookSlice = createSlice({
  name: "addressBook", // Name of the slice
  initialState: addressBookSliceInitialState, // Initial state defined above

  // Define the reducers (functions to update the state)
  reducers: {
    // Set user credentials in the state and cookies
    setMasterInfoFilter: (state, action) => {
      // Update the state with the new user information
      state.masterInfoFilter = action.payload;
    },
    setMasterInfoData: (state, action) => {
      // Update the state with the new user information
      state.masterInfoData = action.payload;
    },
    setContextMenuData: (state, action) => {
      // Update the state with the new user information
      state.contextMenuData = action.payload;
    },
    setContextMenuChanges: (state, action) => {
      // Update the state with the new user information
      state.contextMenuChanges = action.payload;
    },
    setCompanyList: (state, action) => {
      // Update the state with the new user information
      state.companyList = action.payload;
    },
    setCompanyIDSelected: (state, action) => {
      // Update the state with the new user information
      state.companyIDSelected = action.payload;
    },
    setPhoneNbTypes: (state, action) => {
      // Update the state with the new user information
      state.phoneNbTypes = action.payload;
    },
  },
});

// Export the actions to be used in other parts of the application
export const {
  setMasterInfoFilter,
  setMasterInfoData,
  setContextMenuData,
  setContextMenuChanges,
  setCompanyList,
  setCompanyIDSelected,
  setPhoneNbTypes,
} = addressBookSlice.actions;

// Export the reducer to be used in the store configuration
export default addressBookSlice.reducer;
