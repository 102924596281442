import { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import Sidenav from "../Common/SideBar";
import ResponsiveAppBar from "../Common/TopBar";
import HomeComponent from "./Home";
import MasterInfoComponent from "./AddressBook/MasterInfo";
import { useSelector } from "react-redux";

const MainLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const [open, setopen] = useState(false);
  const selectedMenu = useSelector((state) => state.auth.selectedMenu);

  const content = (
    <div className="mainDiv" style={{ width: "100%" }}>
      <Box height={"100%"}>
        {/* display the side bar */}
        <Grid container>
          <Grid item sx={{ width: !open ? "5%" : "14%" }}>
            <Box
              sx={{
                height: "auto",
                minHeight: "100vh",
              }}
            >
              <Sidenav open={open} setopen={setopen} />
            </Box>
          </Grid>
          <Grid item sx={{ width: !open ? "95%" : "86%" }}>
            <Box
              sx={{
                height: "auto",
                minHeight: "100vh",
              }}
            >
              <Box width="5px" className="content" />
              <main className="content">
                {/* display the top bar */}
                <Box className="topbar">
                  <ResponsiveAppBar />
                </Box>
                {/* display all the contents as route with each link */}
                <Box sx={{ overflow: "hidden" }}>
                  <Routes>
                    <Route
                      path="/main"
                      element={<Navigate to={"/main/home"} />}
                    />
                    <Route
                      exact
                      path="home"
                      element={<HomeComponent selectedMenu={selectedMenu} />}
                    />
                    <Route
                      exact
                      path="home/addressBook001a"
                      element={<MasterInfoComponent />}
                    />
                    {/* <Route exact path="home" element={<HomeComponent />} /> */}
                  </Routes>
                </Box>
              </main>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return content;
};

export default MainLayout;
