// Importing necessary modules and components
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import styles from "./sidenav.module.css"; // Importing CSS module for styling
import { NavLink, useNavigate } from "react-router-dom"; // NavLink for navigation
import { Box, Button, Divider, Typography } from "@mui/material"; // MUI components for layout and typography
import { useDispatch, useSelector } from "react-redux"; // Redux hooks for dispatch and state management
import * as Muicon from "@mui/icons-material"; // Import all MUI icons
import {
  logOut,
  setSelectedMenu,
} from "../../../Services/Redux/Reducers/authSliceReducer"; // Action to set selected menu in Redux
import Cookies from "universal-cookie"; // Cookie management

// Sidebar component
export default function Sidenav({ open, setopen }) {
  const userData = useSelector((state) => state.auth.userData); // Get user data from Redux store
  const userInfo = userData?.userData?.userInfo; // Extract user info
  const userMenu = userData?.userMenu; // Extract user menu
  const dispatch = useDispatch(); // Initialize dispatch for Redux actions
  const cookies = new Cookies(); // Initialize cookies for session management
  const navigate = useNavigate();

  // Function to dynamically generate an icon from MUI icons based on the passed icon name (variation)
  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation]
      ? Muicon[variation]
      : Muicon["ArrowForwardIos"]; // Fallback to ArrowForwardIos if icon not found
    return (
      <IconName
        sx={{
          width: !open ? "60%" : "20%", // Icon size adjusts based on whether the sidebar is open or closed
          height: !open ? "60%" : "20%",
        }}
        {...props}
      />
    );
  };

  // Function to toggle the open/closed state of the sidebar
  const toggleOpen = () => {
    setopen(!open);
  };

  // Function to handle menu selection, update Redux store, and set cookies
  const onSelectMenu = (item) => {
    navigate("/main/home");
    dispatch(setSelectedMenu(item));
    // Set selected menu in cookies for persistence
    cookies.set("_selectedMenu", JSON.stringify(item), {
      path: "/",
      expires: new Date(Date.now() + 2592000), // Cookie expires in 30 days
    });
  };

  return (
    // Sidebar container, applying different styles based on whether it's open or closed
    <div className={open ? styles.sidenav : styles.sidenavClosed}>
      {/* Render logo when the sidebar is open */}
      {open && (
        <div
          style={{
            borderRadius: "7.5px", // Rounded corners for the logo box
          }}
        >
          <img src="/taqa_logo.png" width={"100%"} /> {/* Company logo */}
        </div>
      )}

      {/* User info and button to toggle sidebar */}
      {open && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          ml="15px" // Margin left
          marginY={"15%"} // Vertical margin
        >
          {/* Display user information */}
          <Box display="flex" flexDirection="column">
            {/* <Typography variant="h5" color="white">
              {userInfo?.title}. {userInfo?.fullName}{" "}
            </Typography>
            <Typography variant="h6" color={"#b7b7b7"}>
              {userInfo?.address1}
            </Typography> */}
          </Box>

          {/* Button to toggle sidebar (open/close) */}
          <button className={styles.menuBtn} onClick={toggleOpen}>
            {open ? (
              <KeyboardDoubleArrowLeftIcon
                sx={{ width: "2em", height: "2em" }}
              />
            ) : (
              <KeyboardDoubleArrowRightIcon
                sx={{ width: "2em", height: "2em" }}
              />
            )}
          </button>
        </Box>
      )}

      {/* Toggle button when the sidebar is closed */}
      {!open && (
        <button
          className={styles.menuBtn}
          style={{ width: !open ? "80%" : "20%" }} // Adjust button width based on sidebar state
          onClick={toggleOpen}
        >
          {open ? (
            <KeyboardDoubleArrowLeftIcon
              sx={{ width: "100%", height: "100%" }}
            />
          ) : (
            <KeyboardDoubleArrowRightIcon
              sx={{ width: "60%", height: "60%" }}
            />
          )}
        </button>
      )}

      {/* Divider separating the pages section */}
      <Divider
        textAlign="left"
        sx={{
          "&::before, &::after": {
            borderColor: "#a5a5a5", // Custom border color for the divider
          },
          marginTop: !open ? "40%" : "0%", // Adjust margin when the sidebar is closed
        }}
      >
        Pages{" "}
      </Divider>

      {/* Render the user menu items */}
      {userMenu?.map((item) => {
        return (
          <div key={item.pageId} onClick={() => onSelectMenu(item)}>
            {/* NavLink for each menu item */}
            <NavLink
              className={styles.sideitem}
              to={item?.link}
              style={{
                display: "flex",
                justifyContent: !open ? "center" : "left", // Adjust content alignment based on sidebar state
              }}
            >
              {/* Generate the icon for the menu item */}
              {GenerateIcon(
                item?.pageIcon ? item?.pageIcon : "ArrowForwardIos"
              )}

              {/* Display the menu item description only when the sidebar is open */}
              {open && (
                <span
                  className={open ? styles.linkText : styles.linkTextClosed}
                >
                  {item.pageDesc}
                </span>
              )}
            </NavLink>
            {/* Divider between each menu item */}
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Divider
                sx={{
                  borderColor: "rgb(150 150 150 / 54%)", // Custom border color
                  paddingX: "5%", // Horizontal padding
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
